import { Component, OnInit, OnDestroy } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { UntypedFormControl, NgForm, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
//
import { Map, LayerGroupData } from "../map";
//
import { MapService } from "../map.service";
import { SharedService } from "../../../services/shared.service";
import { MixpanelService } from "../../../services/mixpanel.service";
import { AnyTarget } from "../../channels/channel";
import { TitleService } from "../../../services/title.service";

@Component({
    selector: "app-map-form",
    templateUrl: "./map-form.component.html"
})
export class MapFormComponent implements OnInit, OnDestroy {
    map: Map;
    existingMap: Map;
    mapID: string;
    mapNames: string[];
    action: string;

    loading = true;
    saving = false;

    submitted = false;
    isEdit = false;
    isClone = false;

    duplicateLayerName = false;
    constants = Constants;
    selectedTargets: AnyTarget[] = [];

    currentSection = "info";

    // For image upload
    fileData: File = null;
    previewUrl: string | ArrayBuffer = null;
    fileUploadProgress: string = null;
    uploadedFilePath: string = null;

    private mapsSubscription: Subscription;
    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.name)
    ]);
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private sharedService: SharedService,
        private ms: MapService,
        private mixpanelService: MixpanelService,
        private titleService: TitleService
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.mapID = params.get("id");
            this.action = params.get("action");
            if (this.mapID) {
                this.map = Object.assign({}, this.ms.getCachedMap(parseInt(this.mapID, 10)));
                // Check if grid found in cache
                if (this.sharedService.isEmptyObject(this.map)) {
                    this.ms
                        .refreshMaps(true)
                        .pipe(take(1))
                        .subscribe(() => {
                            this.map = Object.assign({}, this.ms.getCachedMap(parseInt(this.mapID, 10)));
                            this.prepForm();
                        });
                } else this.prepForm();
            } else this.prepForm();
        });
    }

    prepForm() {
        if (this.action && this.map) {
            this.tagsControl.setValue(this.map.resourceTags);
            if (this.action === "edit") {
                this.isEdit = true;
                this.nameControl.setValue(this.map.name);
            } else if (this.action === "clone") {
                this.isClone = true;
                this.map.name = null;
            }
        }

        if (!this.map && !this.isClone && !this.isEdit) {
            this.map = new Map();
            this.map.name = "";
            this.map.type = "geographic";
            this.map.region = "north_america";
            this.map.baseMap = "street";
            this.map.config = {
                groups: []
            };
            this.map.config.groups = [];
        }

        // Set Title
        this.titleService.setTitle("MAP", this.action, this.map);
        this.loading = false;
    }

    ngOnInit() {
        this.ms.refreshMaps();

        this.mapsSubscription = this.ms.maps.subscribe((maps: Map[]) => {
            this.mapNames = _.map(maps, "name");
            if (this.isEdit) {
                this.existingMap = Object.assign({}, this.ms.getCachedMap(parseInt(this.mapID, 10)));
                this.mapNames = _.without(this.mapNames, this.existingMap.name);
            }
        });
    }

    ngOnDestroy() {
        this.mapsSubscription.unsubscribe();
    }

    addGroup() {
        // Add id
        let highestID = 0;
        this.map.config.groups.forEach(group => {
            if (group.id) {
                if (group.id >= highestID) {
                    highestID = group.id + 1;
                }
            } else {
                highestID = highestID + 1;
            }
        });
        // Push group
        this.map.config.groups.push({
            id: highestID,
            name: "",
            feeders: [],
            receivers: [],
            zecs: [],
            sources: [],
            mediaconnect_sources: [],
            broadcasters: [],
            targets: []
        });
    }

    removeGroup(index: number) {
        if (index > -1) {
            this.map.config.groups.splice(index, 1);
        }
    }

    async onSubmit() {
        // Check for empty layer
        if (this.map.config.groups) {
            for (const group of this.map.config.groups) {
                if (
                    group.feeders.length === 0 &&
                    group.receivers.length === 0 &&
                    group.zecs.length === 0 &&
                    group.broadcasters.length === 0 &&
                    group.sources.length === 0 &&
                    group.mediaconnect_sources.length === 0 &&
                    group.targets.length === 0
                )
                    return;
            }
        }
        // Check for duplicate layer names
        if (this.map.config.groups) {
            const valueArr = this.map.config.groups.map(group => group.name);
            const isDuplicate = valueArr.some((item, idx) => {
                return valueArr.indexOf(item) !== idx;
            });
            if (isDuplicate) {
                this.duplicateLayerName = true;
                return;
            }
        }
        //
        this.saving = true;
        //
        const model = {
            type: this.map.type,
            region: this.map.region,
            baseMap: this.map.baseMap,
            start_clustered: this.map.start_clustered,
            name: this.nameControl.value,
            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            configVersion: 1,
            config: {
                mediaconnect_flows: this.map.config.mediaconnect_flows || [],
                adaptive_channels: this.map.config.adaptive_channels || [],
                delivery_channels: this.map.config.delivery_channels || [],
                groups: this.map.config.groups || [],
                leafletData: this.map.config.leafletData || []
            }
        };
        //
        if (this.isEdit) {
            model.config.leafletData.forEach((lgd: LayerGroupData, index) => {
                // Remove old leafletData if group no longer exists
                if (lgd.layerInfo.channel) {
                    if (lgd.layerInfo.channelType === "mediaconnect") {
                        if (!model.config.mediaconnect_flows.find(id => id === lgd.layerInfo.channelID))
                            model.config.leafletData.splice(index, 1);
                    } else if (lgd.layerInfo.channelType === "adaptive") {
                        if (!model.config.adaptive_channels.find(id => id === lgd.layerInfo.channelID))
                            model.config.leafletData.splice(index, 1);
                    } else if (lgd.layerInfo.channelType === "delivery") {
                        if (!model.config.delivery_channels.find(id => id === lgd.layerInfo.channelID))
                            model.config.leafletData.splice(index, 1);
                    }
                } else {
                    if (!model.config.groups.find(g => g.id === lgd.layerInfo.layerID))
                        model.config.leafletData.splice(index, 1);
                }
            });
            //
            const result = await this.ms.updateMap(this.map.id, model);
            if (result) {
                this.saving = false;
                this.mixpanelService.sendEvent("update map", {
                    updated: Object.keys(model)
                });
                this.router.navigate([Constants.urls.maps, this.existingMap.id]);
            } else this.saving = false;
        } else {
            const result = await this.ms.addMap(model);
            if (result) {
                this.saving = false;
                this.mixpanelService.sendEvent("create map");
                this.router.navigate([Constants.urls.maps, result.id]);
            } else this.saving = false;
        }
        this.saving = false;
    }

    cancel() {
        this.location.back();
    }

    infoHasErrors() {
        return this.nameControl.invalid || this.tagsControl.invalid;
    }

    configHasErrors(form: NgForm) {
        if (form.form.controls.region && form.form.controls.region.invalid === true) return true;
        if (form.form.controls.baseMap && form.form.controls.baseMap.invalid === true) return true;
        return false;
    }

    channelsHasErrors() {
        return false;
    }

    objectsHasErrors(form: NgForm) {
        if (this.map.config.groups && this.map.config.groups.length > 0) {
            for (let i = 0; i < this.map.config.groups.length; i++) {
                if (form.form.controls["group_name_" + i] && form.form.controls["group_name_" + i].invalid === true)
                    return true;
            }
        }
        if (this.map.config.groups) {
            this.map.config.groups.forEach(group => {
                if (
                    group.feeders.length === 0 &&
                    group.receivers.length === 0 &&
                    group.zecs.length === 0 &&
                    group.broadcasters.length === 0 &&
                    group.sources.length === 0 &&
                    group.mediaconnect_sources.length === 0 &&
                    group.targets.length === 0
                )
                    return true;
            });
        }
        if (this.duplicateLayerName) return true;
    }

    // Scroll Spy Code
    onSectionChange(section: string) {
        this.currentSection = section;
    }

    scrollTo(section: string) {
        document.querySelector("#" + section).scrollIntoView({ behavior: "smooth", block: "start" });
    }

    fileProgress(fileInput: FileList) {
        this.fileData = fileInput.item[0];
        this.preview();
    }

    preview() {
        // Show preview
        const mimeType = this.fileData.type;
        if (mimeType.match(/image\/*/) == null) {
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(this.fileData);
        reader.onload = () => {
            this.previewUrl = reader.result;
        };
    }
}
